import _Short2 from "../Interval/Short";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Short = _Short2;
exports.default = Array.from({
  length: 6
}, function () {
  return _Short.T;
}); // https://en.wikipedia.org/wiki/Whole_tone_scale

exports = exports["default"];
export default exports;