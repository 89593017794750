import _getRoot3 from "../getRoot";
import _getAccidental3 from "../getAccidental";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getRoot = _getRoot3;

var _getRoot2 = _interopRequireDefault(_getRoot);

var _getAccidental = _getAccidental3;

var _getAccidental2 = _interopRequireDefault(_getAccidental);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getNote = function getNote(note) {
  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  return "" + (0, _getRoot2.default)(note) + ((0, _getAccidental2.default)(note) || "");
};

exports.default = getNote;
exports = exports["default"];
export default exports;