import _getOctave3 from "../getOctave";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getOctave = _getOctave3;

var _getOctave2 = _interopRequireDefault(_getOctave);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var hasOctave = function hasOctave(note) {
  if (!(0, _isNote2.default)(note)) {
    return false;
  }

  return typeof (0, _getOctave2.default)(note) !== "undefined";
};

exports.default = hasOctave;
exports = exports["default"];
export default exports;