import _getOctave3 from "../getOctave";
import _hasOctave3 from "../hasOctave";
import _getChromaticCPosition3 from "../getChromaticCPosition";
import _areNotes3 from "../areNotes";
import _Interval2 from "../constants/Interval";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getOctave = _getOctave3;

var _getOctave2 = _interopRequireDefault(_getOctave);

var _hasOctave = _hasOctave3;

var _hasOctave2 = _interopRequireDefault(_hasOctave);

var _getChromaticCPosition = _getChromaticCPosition3;

var _getChromaticCPosition2 = _interopRequireDefault(_getChromaticCPosition);

var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

var _Interval = _Interval2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var getInterval = function getInterval(note, note2) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  var note1Position = (0, _getChromaticCPosition2.default)(note);
  var note2Position = (0, _getChromaticCPosition2.default)(note2);
  var difference = note2Position - note1Position;
  var octave1 = (0, _hasOctave2.default)(note) ? (0, _getOctave2.default)(note) : -1;
  var octave2 = (0, _hasOctave2.default)(note2) ? (0, _getOctave2.default)(note2) : -1;

  if (octave1 === -1 && octave2 === -1) {
    if (difference < 0 && direction === 1) difference = _Interval.OCTAVE - Math.abs(difference);
    if (direction === -1 && difference > 0) difference -= _Interval.OCTAVE;
    return difference;
  }

  return difference + (octave2 - octave1) * _Interval.OCTAVE;
};

var getIntervals = function getIntervals(notes) {
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref2$fromRoot = _ref2.fromRoot,
      fromRoot = _ref2$fromRoot === undefined ? false : _ref2$fromRoot,
      _ref2$direction = _ref2.direction,
      direction = _ref2$direction === undefined ? 1 : _ref2$direction;

  if (![-1, 1].includes(direction)) {
    throw new Error("Direction should be 1 (up) or -1 (down)");
  }

  if (!(0, _areNotes2.default)(notes) || notes.length < 2) {
    throw new Error("You can only calculate intervals for a uniform array (minimum size 2) of notes with or without octave");
  }

  var intervals = notes.reduce(function (acc, note1, i) {
    var note2 = notes[i + 1];
    if (!note2) return acc;
    var interval = getInterval(note1, note2, {
      direction: direction
    });
    return [].concat(_toConsumableArray(acc), [interval]);
  }, []);

  if (fromRoot) {
    intervals = intervals.map(function (interval, i) {
      return intervals.slice(0, i + 1).reduce(function (acc, v) {
        return acc + v;
      }, 0);
    });
  }

  return intervals;
};

exports.default = getIntervals;
exports = exports["default"];
export default exports;