import _transpose3 from "../transpose";
import _Names2 from "../constants/Interval/Names";
import _isScale3 from "../isScale";
import _isDescending3 from "../isDescending";
import _isAscending3 from "../isAscending";
import _getNote3 from "../getNote";
import _hasOctave3 from "../hasOctave";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _transpose = _transpose3;

var _transpose2 = _interopRequireDefault(_transpose);

var _Names = _Names2;
var _isScale = _isScale3;

var _isScale2 = _interopRequireDefault(_isScale);

var _isDescending = _isDescending3;

var _isDescending2 = _interopRequireDefault(_isDescending);

var _isAscending = _isAscending3;

var _isAscending2 = _interopRequireDefault(_isAscending);

var _getNote = _getNote3;

var _getNote2 = _interopRequireDefault(_getNote);

var _hasOctave = _hasOctave3;

var _hasOctave2 = _interopRequireDefault(_hasOctave);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var calculateInterval = function calculateInterval(scale) {
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  if ((0, _isDescending2.default)(scale)) return -_Names.OCTAVE;
  if ((0, _isAscending2.default)(scale)) return _Names.OCTAVE;
  return direction === 1 ? _Names.OCTAVE : -_Names.OCTAVE;
};

var normalize = function normalize(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _isScale2.default)(scale, {
    direction: direction
  })) {
    throw new Error(JSON.stringify(scale) + " is not a valid scale");
  }

  var start = scale[0];
  var end = scale[scale.length - 1];
  var normalizedScale = (0, _getNote2.default)(start) !== (0, _getNote2.default)(end) ? [].concat(_toConsumableArray(scale), [(0, _transpose2.default)(scale[0], calculateInterval(scale, direction))]) : scale;
  if ((0, _isDescending2.default)(scale) || scale.every(function (n) {
    return !(0, _hasOctave2.default)(n);
  }) && direction === -1) normalizedScale.reverse();
  return normalizedScale;
};

exports.default = normalize;
exports = exports["default"];
export default exports;