import _getChromaticCPosition3 from "../getChromaticCPosition";
import _NOTES3 from "../constants/NOTES";
import _Interval3 from "../constants/Interval";
import _noteToObject3 from "../noteToObject";
import _objectToNote3 from "../objectToNote";
import _transferStyle3 from "../transferStyle";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getChromaticCPosition = _getChromaticCPosition3;

var _getChromaticCPosition2 = _interopRequireDefault(_getChromaticCPosition);

var _NOTES = _NOTES3;

var _NOTES2 = _interopRequireDefault(_NOTES);

var _Interval = _Interval3;

var _Interval2 = _interopRequireDefault(_Interval);

var _noteToObject = _noteToObject3;

var _noteToObject2 = _interopRequireDefault(_noteToObject);

var _objectToNote = _objectToNote3;

var _objectToNote2 = _interopRequireDefault(_objectToNote);

var _transferStyle = _transferStyle3;

var _transferStyle2 = _interopRequireDefault(_transferStyle);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getNormalizedPosition = function getNormalizedPosition(position, interval) {
  var normalizedPosition = (position + interval) % _NOTES2.default.length;
  if (normalizedPosition >= 0) return normalizedPosition;
  return _NOTES2.default.length - Math.abs(normalizedPosition);
};

var transpose = function transpose(note, interval) {
  var reference = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  if (typeof interval !== "number") {
    throw new Error("Interval must be a number");
  }

  if (reference && !(0, _isNote2.default)(reference)) {
    throw new Error("\"" + reference + "\" is not a valid note.");
  }

  var position = (0, _getChromaticCPosition2.default)(note);
  var oldNoteObject = (0, _noteToObject2.default)(note);
  var calculatedPosition = position + interval;
  var normalizedPosition = getNormalizedPosition(position, interval);
  var octave = Math.floor(calculatedPosition / _NOTES2.default.length);
  var newNote = _NOTES2.default[normalizedPosition][0];
  var newNoteObject = (0, _noteToObject2.default)(newNote);
  newNoteObject.octave = undefined;

  if (typeof oldNoteObject.octave !== "undefined") {
    newNoteObject.octave = oldNoteObject.octave + octave;
  }

  var parsedNewNote = (0, _objectToNote2.default)(newNoteObject);
  return (0, _transferStyle2.default)(parsedNewNote, reference || note);
};

exports.default = transpose;
exports = exports["default"];
export default exports;