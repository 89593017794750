import _getNoteOnDegree3 from "../getNoteOnDegree";
import _isDiatonic3 from "../isDiatonic";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getNoteOnDegree = _getNoteOnDegree3;

var _getNoteOnDegree2 = _interopRequireDefault(_getNoteOnDegree);

var _isDiatonic = _isDiatonic3;

var _isDiatonic2 = _interopRequireDefault(_isDiatonic);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getSupertonic = function getSupertonic(diatonicScale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _isDiatonic2.default)(diatonicScale, {
    direction: direction
  })) {
    throw new Error(JSON.stringify(diatonicScale) + " is not a diatonic scale.");
  }

  return (0, _getNoteOnDegree2.default)(diatonicScale, 2, {
    direction: direction
  });
};

exports.default = getSupertonic;
exports = exports["default"];
export default exports;