import _Chord2 from "../constants/Chord";
import _getIntervals3 from "../getIntervals";
import _areNotes3 from "../areNotes";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Chord = _Chord2;
var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
} // https://en.wikipedia.org/wiki/Triad_(music)


var isTriad = function isTriad(chord) {
  if (!(0, _areNotes2.default)(chord)) return false;
  var intervals = [0].concat(_toConsumableArray((0, _getIntervals2.default)(chord, {
    fromRoot: true
  })));
  var triads = [_Chord.MAJOR, _Chord.MINOR, _Chord.DIMINISHED, _Chord.AUGMENTED];
  return triads.some(function (t) {
    return t.join(",") === intervals.join(",");
  });
};

exports.default = isTriad;
exports = exports["default"];
export default exports;