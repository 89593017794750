import _IONIAN3 from "../Mode/IONIAN";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _IONIAN = _IONIAN3;

var _IONIAN2 = _interopRequireDefault(_IONIAN);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
} // https://en.wikipedia.org/wiki/Major_scale
// = https://en.wikipedia.org/wiki/Ionian_mode


exports.default = [].concat(_toConsumableArray(_IONIAN2.default));
exports = exports["default"];
export default exports;