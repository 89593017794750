import _ALGERIAN3 from "./ALGERIAN";
import _ALTERED3 from "./ALTERED";
import _ARABIC3 from "./ARABIC";
import _AUGMENTED3 from "./AUGMENTED";
import _BALINESE3 from "./BALINESE";
import _BLUES3 from "./BLUES";
import _BYZANTINE3 from "./BYZANTINE";
import _CHINESE3 from "./CHINESE";
import _CHROMATIC3 from "./CHROMATIC";
import _DOUBLE_HARMONIC3 from "./DOUBLE_HARMONIC";
import _ENIGMATIC3 from "./ENIGMATIC";
import _GYPSY_MAJOR3 from "./GYPSY_MAJOR";
import _HARMONIC_MINOR3 from "./HARMONIC_MINOR";
import _HIRAJOSHI3 from "./HIRAJOSHI";
import _IN_SEN3 from "./IN_SEN";
import _JAPANESE3 from "./JAPANESE";
import _MAJOR_PENTATONIC3 from "./MAJOR_PENTATONIC";
import _MAJOR3 from "./MAJOR";
import _MELODIC_MINOR3 from "./MELODIC_MINOR";
import _MINOR_PENTATONIC3 from "./MINOR_PENTATONIC";
import _MONGOLIAN3 from "./MONGOLIAN";
import _NATURAL_MINOR3 from "./NATURAL_MINOR";
import _PELOG3 from "./PELOG";
import _PROMETHEUS3 from "./PROMETHEUS";
import _WHOLE_TONE3 from "./WHOLE_TONE";
import _YO3 from "./YO";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _ALGERIAN = _ALGERIAN3;

var _ALGERIAN2 = _interopRequireDefault(_ALGERIAN);

var _ALTERED = _ALTERED3;

var _ALTERED2 = _interopRequireDefault(_ALTERED);

var _ARABIC = _ARABIC3;

var _ARABIC2 = _interopRequireDefault(_ARABIC);

var _AUGMENTED = _AUGMENTED3;

var _AUGMENTED2 = _interopRequireDefault(_AUGMENTED);

var _BALINESE = _BALINESE3;

var _BALINESE2 = _interopRequireDefault(_BALINESE);

var _BLUES = _BLUES3;

var _BLUES2 = _interopRequireDefault(_BLUES);

var _BYZANTINE = _BYZANTINE3;

var _BYZANTINE2 = _interopRequireDefault(_BYZANTINE);

var _CHINESE = _CHINESE3;

var _CHINESE2 = _interopRequireDefault(_CHINESE);

var _CHROMATIC = _CHROMATIC3;

var _CHROMATIC2 = _interopRequireDefault(_CHROMATIC);

var _DOUBLE_HARMONIC = _DOUBLE_HARMONIC3;

var _DOUBLE_HARMONIC2 = _interopRequireDefault(_DOUBLE_HARMONIC);

var _ENIGMATIC = _ENIGMATIC3;

var _ENIGMATIC2 = _interopRequireDefault(_ENIGMATIC);

var _GYPSY_MAJOR = _GYPSY_MAJOR3;

var _GYPSY_MAJOR2 = _interopRequireDefault(_GYPSY_MAJOR);

var _HARMONIC_MINOR = _HARMONIC_MINOR3;

var _HARMONIC_MINOR2 = _interopRequireDefault(_HARMONIC_MINOR);

var _HIRAJOSHI = _HIRAJOSHI3;

var _HIRAJOSHI2 = _interopRequireDefault(_HIRAJOSHI);

var _IN_SEN = _IN_SEN3;

var _IN_SEN2 = _interopRequireDefault(_IN_SEN);

var _JAPANESE = _JAPANESE3;

var _JAPANESE2 = _interopRequireDefault(_JAPANESE);

var _MAJOR_PENTATONIC = _MAJOR_PENTATONIC3;

var _MAJOR_PENTATONIC2 = _interopRequireDefault(_MAJOR_PENTATONIC);

var _MAJOR = _MAJOR3;

var _MAJOR2 = _interopRequireDefault(_MAJOR);

var _MELODIC_MINOR = _MELODIC_MINOR3;

var _MELODIC_MINOR2 = _interopRequireDefault(_MELODIC_MINOR);

var _MINOR_PENTATONIC = _MINOR_PENTATONIC3;

var _MINOR_PENTATONIC2 = _interopRequireDefault(_MINOR_PENTATONIC);

var _MONGOLIAN = _MONGOLIAN3;

var _MONGOLIAN2 = _interopRequireDefault(_MONGOLIAN);

var _NATURAL_MINOR = _NATURAL_MINOR3;

var _NATURAL_MINOR2 = _interopRequireDefault(_NATURAL_MINOR);

var _PELOG = _PELOG3;

var _PELOG2 = _interopRequireDefault(_PELOG);

var _PROMETHEUS = _PROMETHEUS3;

var _PROMETHEUS2 = _interopRequireDefault(_PROMETHEUS);

var _WHOLE_TONE = _WHOLE_TONE3;

var _WHOLE_TONE2 = _interopRequireDefault(_WHOLE_TONE);

var _YO = _YO3;

var _YO2 = _interopRequireDefault(_YO);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // https://www.scales-chords.com/scalenav.php


exports.default = {
  ALGERIAN: _ALGERIAN2.default,
  ALTERED: _ALTERED2.default,
  ARABIC: _ARABIC2.default,
  AUGMENTED: _AUGMENTED2.default,
  BALINESE: _BALINESE2.default,
  BLUES: _BLUES2.default,
  BYZANTINE: _BYZANTINE2.default,
  CHINESE: _CHINESE2.default,
  CHROMATIC: _CHROMATIC2.default,
  DOUBLE_HARMONIC: _DOUBLE_HARMONIC2.default,
  ENIGMATIC: _ENIGMATIC2.default,
  GYPSY_MAJOR: _GYPSY_MAJOR2.default,
  HARMONIC_MINOR: _HARMONIC_MINOR2.default,
  HIRAJOSHI: _HIRAJOSHI2.default,
  IN_SEN: _IN_SEN2.default,
  JAPANESE: _JAPANESE2.default,
  MAJOR_PENTATONIC: _MAJOR_PENTATONIC2.default,
  MAJOR: _MAJOR2.default,
  MELODIC_MINOR: _MELODIC_MINOR2.default,
  MINOR_PENTATONIC: _MINOR_PENTATONIC2.default,
  MONGOLIAN: _MONGOLIAN2.default,
  NATURAL_MINOR: _NATURAL_MINOR2.default,
  PELOG: _PELOG2.default,
  PROMETHEUS: _PROMETHEUS2.default,
  WHOLE_TONE: _WHOLE_TONE2.default,
  YO: _YO2.default
};
exports = exports["default"];
export default exports;