import _isScale3 from "../isScale";
import _getIntervals3 from "../getIntervals";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _isScale = _isScale3;

var _isScale2 = _interopRequireDefault(_isScale);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isAscending = function isAscending(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _isScale2.default)(scale, {
    direction: direction
  })) {
    return false;
  }

  var intervals = (0, _getIntervals2.default)(scale, {
    direction: direction
  });
  return intervals.every(function (interval) {
    return interval < 0;
  });
};

exports.default = isAscending;
exports = exports["default"];
export default exports;