import _isScale3 from "../isScale";
import _getIntervals3 from "../getIntervals";
import _normalize3 from "../normalize";
import _Names2 from "../constants/Interval/Names";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _isScale = _isScale3;

var _isScale2 = _interopRequireDefault(_isScale);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

var _Names = _Names2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // https://en.wikipedia.org/wiki/Anhemitonic_scale


var isAnhemitonic = function isAnhemitonic(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _isScale2.default)(scale, {
    direction: direction
  })) return false;
  var normalizedScale = (0, _normalize2.default)(scale, {
    direction: direction
  });
  var intervals = (0, _getIntervals2.default)(normalizedScale);
  return intervals.filter(function (i) {
    return i === _Names.SEMITONE;
  }).length === 0;
};

exports.default = isAnhemitonic;
exports = exports["default"];
export default exports;