import _getChromaticCPosition3 from "../getChromaticCPosition";
import _accidentalToSymbol3 from "../accidentalToSymbol";
import _noteToObject4 from "../noteToObject";
import _objectToNote3 from "../objectToNote";
import _isNote3 from "../isNote";
import _NOTES3 from "../constants/NOTES";
import _isSharp3 from "../isSharp";
import _Accidental2 from "../constants/Accidental";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _getChromaticCPosition = _getChromaticCPosition3;

var _getChromaticCPosition2 = _interopRequireDefault(_getChromaticCPosition);

var _accidentalToSymbol = _accidentalToSymbol3;

var _accidentalToSymbol2 = _interopRequireDefault(_accidentalToSymbol);

var _noteToObject2 = _noteToObject4;

var _noteToObject3 = _interopRequireDefault(_noteToObject2);

var _objectToNote = _objectToNote3;

var _objectToNote2 = _interopRequireDefault(_objectToNote);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _NOTES = _NOTES3;

var _NOTES2 = _interopRequireDefault(_NOTES);

var _isSharp = _isSharp3;

var _isSharp2 = _interopRequireDefault(_isSharp);

var _Accidental = _Accidental2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var sharpToFlat = function sharpToFlat(note) {
  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  if (!(0, _isSharp2.default)(note)) return note;

  var _noteToObject = (0, _noteToObject3.default)(note),
      octave = _noteToObject.octave,
      accidentalType = _noteToObject.accidentalType;

  var normalizedNote = (0, _accidentalToSymbol2.default)(note);
  var chromaCPosition = (0, _getChromaticCPosition2.default)(normalizedNote);

  var convertedNote = _NOTES2.default[chromaCPosition].map(_noteToObject3.default).find(function (n) {
    return n.accidental === _Accidental.FLAT;
  });

  if (!convertedNote) return note;
  return (0, _objectToNote2.default)(_extends({}, convertedNote, {
    accidentalType: accidentalType,
    octave: octave
  }));
};

exports.default = sharpToFlat;
exports = exports["default"];
export default exports;