import _Names2 from "../constants/Interval/Names";
import _isHemitonic3 from "../isHemitonic";
import _getIntervals3 from "../getIntervals";
import _normalize3 from "../normalize";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Names = _Names2;
var _isHemitonic = _isHemitonic3;

var _isHemitonic2 = _interopRequireDefault(_isHemitonic);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
} // https://en.wikipedia.org/wiki/Anhemitonic_scale


var isCohemitonic = function isCohemitonic(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _isHemitonic2.default)(scale, {
    direction: direction
  })) return false;
  var normalizedScale = (0, _normalize2.default)(scale, {
    direction: direction
  });
  var intervals = (0, _getIntervals2.default)(normalizedScale);
  var groups = intervals.reduce(function (acc, interval, i) {
    if (interval === _Names.SEMITONE) {
      if (acc.length === 0) return [[interval]];
      if (intervals[i - 1] !== _Names.SEMITONE) return [].concat(_toConsumableArray(acc), [[interval]]);
      acc[acc.length - 1].push(interval);
      return acc;
    }

    return acc;
  }, []);
  return groups.filter(function (group) {
    return group.length >= 2;
  }).length >= 2 || groups.filter(function (group) {
    return group.length > 2;
  }).length >= 1;
};

exports.default = isCohemitonic;
exports = exports["default"];
export default exports;