import _MINOR3 from "./MINOR";
import _MinorMajor2 from "../Interval/MinorMajor";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _MINOR = _MINOR3;

var _MINOR2 = _interopRequireDefault(_MINOR);

var _MinorMajor = _MinorMajor2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
} // https://en.wikipedia.org/wiki/Minor_seventh_chord


exports.default = [].concat(_toConsumableArray(_MINOR2.default), [_MinorMajor.MINOR_SEVENTH]);
exports = exports["default"];
export default exports;