import _DOUBLE_HARMONIC3 from "./DOUBLE_HARMONIC";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _DOUBLE_HARMONIC = _DOUBLE_HARMONIC3;

var _DOUBLE_HARMONIC2 = _interopRequireDefault(_DOUBLE_HARMONIC);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
} // https://en.wikipedia.org/wiki/Double_harmonic_scale


exports.default = [].concat(_toConsumableArray(_DOUBLE_HARMONIC2.default));
exports = exports["default"];
export default exports;