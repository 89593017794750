import _SCIENTIFIC_NOTE3 from "../constants/Regex/SCIENTIFIC_NOTE";
import _isNote3 from "../isNote";
import _Accidental2 from "../constants/Accidental";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

var _SCIENTIFIC_NOTE = _SCIENTIFIC_NOTE3;

var _SCIENTIFIC_NOTE2 = _interopRequireDefault(_SCIENTIFIC_NOTE);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _Accidental = _Accidental2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isFlat = function isFlat(accidental) {
  return [_Accidental.FLAT_LETTER, _Accidental.FLAT_SYMBOL].includes(accidental);
};

var isSharp = function isSharp(accidental) {
  return [_Accidental.SHARP_LETTER, _Accidental.SHARP_SYMBOL].includes(accidental);
};

var isSymbol = function isSymbol(accidental) {
  return [_Accidental.FLAT_SYMBOL, _Accidental.SHARP_SYMBOL].includes(accidental);
};

var isLetter = function isLetter(accidental) {
  return [_Accidental.FLAT_LETTER, _Accidental.SHARP_LETTER].includes(accidental);
};

var getAccidental = function getAccidental(accidental) {
  if (isSharp(accidental)) return _Accidental.SHARP;else if (isFlat(accidental)) return _Accidental.FLAT;
  return undefined;
};

var getAccidentalType = function getAccidentalType(accidental) {
  if (isSymbol(accidental)) return _Accidental.SYMBOL;else if (isLetter(accidental)) return _Accidental.LETTER;
  return undefined;
};

var parseAccidental = function parseAccidental(acc) {
  return {
    accidental: getAccidental(acc),
    accidentalType: getAccidentalType(acc)
  };
};

var noteToObject = function noteToObject(scientificNote) {
  if (!(0, _isNote2.default)(scientificNote)) {
    throw new Error("\"" + scientificNote + "\" is not a valid scientific note.");
  }

  var _SCIENTIFIC_NOTE$exec = _SCIENTIFIC_NOTE2.default.exec(scientificNote),
      _SCIENTIFIC_NOTE$exec2 = _slicedToArray(_SCIENTIFIC_NOTE$exec, 4),
      root = _SCIENTIFIC_NOTE$exec2[1],
      accidental = _SCIENTIFIC_NOTE$exec2[2],
      octave = _SCIENTIFIC_NOTE$exec2[3];

  return _extends({
    root: root.toUpperCase()
  }, parseAccidental(accidental), {
    octave: typeof octave !== "undefined" ? Number(octave) : undefined
  });
};

exports.default = noteToObject;
exports = exports["default"];
export default exports;