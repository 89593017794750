import _areNotes3 from "../areNotes";
import _getIntervals3 from "../getIntervals";
import _Names2 from "../constants/Interval/Names";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _Names = _Names2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isScale = function isScale(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _areNotes2.default)(scale)) return false;

  try {
    var intervals = (0, _getIntervals2.default)(scale, {
      direction: direction
    });
    var isInUniformDirection = intervals.every(function (interval) {
      return interval >= 0;
    }) || intervals.every(function (interval) {
      return interval <= 0;
    });
    var hasZeroIntervals = intervals.some(function (interval) {
      return interval === _Names.ROOT;
    });
    if (!isInUniformDirection || hasZeroIntervals) return false;
    var intervalsFromRoot = (0, _getIntervals2.default)(scale, {
      fromRoot: true,
      direction: direction
    });
    return intervalsFromRoot[intervalsFromRoot.length - 1] <= _Names.OCTAVE;
  } catch (e) {
    return false;
  }
};

exports.default = isScale;
exports = exports["default"];
export default exports;