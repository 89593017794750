import _Short2 from "../Interval/Short";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Short = _Short2;
exports.default = [_Short.T, _Short.T, _Short.S, _Short.T, _Short.T, _Short.T, _Short.S]; // https://en.wikipedia.org/wiki/Ionian_mode
// = https://en.wikipedia.org/wiki/Major_scale
// C, D, E, F, G, A, B, C

exports = exports["default"];
export default exports;