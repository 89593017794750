import _Names2 from "../constants/Interval/Names";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Names = _Names2;

var isTone = function isTone(interval) {
  return interval === _Names.TONE;
};

exports.default = isTone;
exports = exports["default"];
export default exports;