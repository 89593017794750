import _Names2 from "../Interval/Names";
import _MinorMajor2 from "../Interval/MinorMajor";
import _AugmentedDiminished2 from "../Interval/AugmentedDiminished";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Names = _Names2;
var _MinorMajor = _MinorMajor2;
var _AugmentedDiminished = _AugmentedDiminished2;
exports.default = [_Names.ROOT, _MinorMajor.MINOR_THIRD, _AugmentedDiminished.DIMINISHED_FIFTH]; // https://en.wikipedia.org/wiki/Diminished_triad

exports = exports["default"];
export default exports;