import _isDiatonic3 from "../isDiatonic";
import _Mode3 from "../constants/Mode";
import _getIntervals3 from "../getIntervals";
import _normalize3 from "../normalize";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _isDiatonic = _isDiatonic3;

var _isDiatonic2 = _interopRequireDefault(_isDiatonic);

var _Mode = _Mode3;

var _Mode2 = _interopRequireDefault(_Mode);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // https://en.wikipedia.org/wiki/Mode_(music)


var isMode = function isMode(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (![-1, 1].includes(direction)) {
    throw new Error("Direction should be 1 (up) or -1 (down)");
  }

  if (!(0, _isDiatonic2.default)(scale)) return false;
  if (direction === -1) scale.reverse();
  var normalizedScale = (0, _normalize2.default)(scale);
  var intervals = (0, _getIntervals2.default)(normalizedScale);
  var modes = Object.keys(_Mode2.default).map(function (k) {
    return _Mode2.default[k];
  });
  return modes.some(function (m) {
    return m.join(",") === intervals.join(",");
  });
};

exports.default = isMode;
exports = exports["default"];
export default exports;