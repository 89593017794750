var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var areIntervals = function areIntervals(intervals) {
  return Array.isArray(intervals) && intervals.length > 1 && intervals.every(function (i) {
    return typeof i === "number";
  }) && (intervals.every(function (i) {
    return i >= 0;
  }) || intervals.every(function (i) {
    return i <= 0;
  }));
};

exports.default = areIntervals;
exports = exports["default"];
export default exports;