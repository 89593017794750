import _Short2 from "./Short";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCTAVE = exports.TRITONE = exports.TONE = exports.SEMITONE = exports.ROOT = undefined;
var _Short = _Short2;
var ROOT = exports.ROOT = _Short.R; // https://en.wikipedia.org/wiki/Interval_(music)#Interval_number_and_quality

var SEMITONE = exports.SEMITONE = _Short.S;
var TONE = exports.TONE = _Short.T;
var TRITONE = exports.TRITONE = _Short.TT;
var OCTAVE = exports.OCTAVE = _Short.O;
exports.default = {
  ROOT: ROOT,
  SEMITONE: SEMITONE,
  TONE: TONE,
  TRITONE: TRITONE,
  OCTAVE: OCTAVE
};
export default exports;