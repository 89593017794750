import _sharpToFlat3 from "../sharpToFlat";
import _flatToSharp3 from "../flatToSharp";
import _noteToObject5 from "../noteToObject";
import _isNote3 from "../isNote";
import _hasAccidental3 from "../hasAccidental";
import _Accidental2 from "../constants/Accidental";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _sharpToFlat = _sharpToFlat3;

var _sharpToFlat2 = _interopRequireDefault(_sharpToFlat);

var _flatToSharp = _flatToSharp3;

var _flatToSharp2 = _interopRequireDefault(_flatToSharp);

var _noteToObject3 = _noteToObject5;

var _noteToObject4 = _interopRequireDefault(_noteToObject3);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _hasAccidental = _hasAccidental3;

var _hasAccidental2 = _interopRequireDefault(_hasAccidental);

var _Accidental = _Accidental2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var transferAccidental = function transferAccidental(note, referenceNote) {
  if (!(0, _isNote2.default)(referenceNote)) {
    throw new Error("\"" + referenceNote + "\" is not a valid reference note.");
  }

  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  if (!(0, _hasAccidental2.default)(referenceNote)) return note;

  var _noteToObject = (0, _noteToObject4.default)(referenceNote),
      rAccidental = _noteToObject.accidental;

  var _noteToObject2 = (0, _noteToObject4.default)(note),
      iAccidental = _noteToObject2.accidental;

  if (rAccidental === iAccidental) return note;
  if (rAccidental === _Accidental.FLAT) return (0, _sharpToFlat2.default)(note);
  return (0, _flatToSharp2.default)(note);
};

exports.default = transferAccidental;
exports = exports["default"];
export default exports;