import _Short2 from "../Interval/Short";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Short = _Short2;
exports.default = [_Short.S, _Short.T, _Short.T, _Short.S, _Short.T, _Short.T, _Short.T]; // https://en.wikipedia.org/wiki/Locrian_mode
// B, C, D, E, F, G, A, B

exports = exports["default"];
export default exports;