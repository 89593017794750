import _transpose3 from "../transpose";
import _areIntervals3 from "../areIntervals";
import _Names2 from "../constants/Interval/Names";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _transpose = _transpose3;

var _transpose2 = _interopRequireDefault(_transpose);

var _areIntervals = _areIntervals3;

var _areIntervals2 = _interopRequireDefault(_areIntervals);

var _Names = _Names2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var createChord = function createChord(root, chord) {
  if (!(0, _areIntervals2.default)(chord)) {
    throw new Error("Provide a valid collection of chord intervals ex: [0, 1, 3]");
  }

  if (chord[0] !== _Names.ROOT) {
    throw new Error("First interval of chord should be 0 (Root)");
  }

  return chord.reduce(function (acc, interval) {
    return [].concat(_toConsumableArray(acc), [(0, _transpose2.default)(root, interval)]);
  }, []);
};

exports.default = createChord;
exports = exports["default"];
export default exports;