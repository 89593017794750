import _transpose3 from "../transpose";
import _areIntervals3 from "../areIntervals";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _transpose = _transpose3;

var _transpose2 = _interopRequireDefault(_transpose);

var _areIntervals = _areIntervals3;

var _areIntervals2 = _interopRequireDefault(_areIntervals);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var createScale = function createScale(root, scale) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$includeRootEnd = _ref.includeRootEnd,
      includeRootEnd = _ref$includeRootEnd === undefined ? false : _ref$includeRootEnd;

  if (!(0, _areIntervals2.default)(scale)) {
    throw new Error("Provide a valid collection of scale intervals ex: [1, 2, 1, 2, 1]");
  }

  var notes = scale.reduce(function (acc, interval) {
    return [].concat(_toConsumableArray(acc), [(0, _transpose2.default)(acc[acc.length - 1], interval, root)]);
  }, [root]);
  return includeRootEnd ? notes : notes.slice(0, -1);
};

exports.default = createScale;
exports = exports["default"];
export default exports;