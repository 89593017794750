import _Short2 from "./Short";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.P8 = exports.PERFECT_OCTAVE = exports.M7 = exports.MAJOR_SEVENTH = exports.m7 = exports.MINOR_SEVENTH = exports.M6 = exports.MAJOR_SIXTH = exports.m6 = exports.MINOR_SIXTH = exports.P5 = exports.PERFECT_FIFTH = exports.P4 = exports.PERFECT_FOURTH = exports.M3 = exports.MAJOR_THIRD = exports.m3 = exports.MINOR_THIRD = exports.M2 = exports.MAJOR_SECOND = exports.m2 = exports.MINOR_SECOND = exports.P1 = exports.PERFECT_UNISON = undefined;
var _Short = _Short2;
var PERFECT_UNISON = exports.PERFECT_UNISON = _Short.R; // https://en.wikipedia.org/wiki/Interval_(music)#Interval_number_and_quality

var P1 = exports.P1 = PERFECT_UNISON;
var MINOR_SECOND = exports.MINOR_SECOND = _Short.S;
var m2 = exports.m2 = MINOR_SECOND;
var MAJOR_SECOND = exports.MAJOR_SECOND = _Short.T;
var M2 = exports.M2 = MAJOR_SECOND;
var MINOR_THIRD = exports.MINOR_THIRD = 3 * _Short.S;
var m3 = exports.m3 = MINOR_THIRD;
var MAJOR_THIRD = exports.MAJOR_THIRD = 4 * _Short.S;
var M3 = exports.M3 = MAJOR_THIRD;
var PERFECT_FOURTH = exports.PERFECT_FOURTH = 5 * _Short.S;
var P4 = exports.P4 = PERFECT_FOURTH;
var PERFECT_FIFTH = exports.PERFECT_FIFTH = 7 * _Short.S;
var P5 = exports.P5 = PERFECT_FIFTH;
var MINOR_SIXTH = exports.MINOR_SIXTH = 8 * _Short.S;
var m6 = exports.m6 = MINOR_SIXTH;
var MAJOR_SIXTH = exports.MAJOR_SIXTH = 9 * _Short.S;
var M6 = exports.M6 = MAJOR_SIXTH;
var MINOR_SEVENTH = exports.MINOR_SEVENTH = 10 * _Short.S;
var m7 = exports.m7 = MINOR_SEVENTH;
var MAJOR_SEVENTH = exports.MAJOR_SEVENTH = 11 * _Short.S;
var M7 = exports.M7 = MAJOR_SEVENTH;
var PERFECT_OCTAVE = exports.PERFECT_OCTAVE = _Short.O;
var P8 = exports.P8 = PERFECT_OCTAVE;
exports.default = {
  PERFECT_UNISON: PERFECT_UNISON,
  P1: P1,
  MINOR_SECOND: MINOR_SECOND,
  m2: m2,
  MAJOR_SECOND: MAJOR_SECOND,
  M2: M2,
  MINOR_THIRD: MINOR_THIRD,
  m3: m3,
  MAJOR_THIRD: MAJOR_THIRD,
  M3: M3,
  PERFECT_FOURTH: PERFECT_FOURTH,
  P4: P4,
  PERFECT_FIFTH: PERFECT_FIFTH,
  P5: P5,
  MINOR_SIXTH: MINOR_SIXTH,
  m6: m6,
  MAJOR_SIXTH: MAJOR_SIXTH,
  M6: M6,
  MINOR_SEVENTH: MINOR_SEVENTH,
  m7: m7,
  MAJOR_SEVENTH: MAJOR_SEVENTH,
  M7: M7,
  PERFECT_OCTAVE: PERFECT_OCTAVE,
  P8: P8
};
export default exports;