import _Names2 from "../constants/Interval/Names";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Names = _Names2;

var isOctave = function isOctave(interval) {
  return interval === _Names.OCTAVE;
};

exports.default = isOctave;
exports = exports["default"];
export default exports;