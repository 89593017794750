import _MinorMajor2 from "../constants/Interval/MinorMajor";
import _AugmentedDiminished2 from "../constants/Interval/AugmentedDiminished";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _MinorMajor = _MinorMajor2;
var _AugmentedDiminished = _AugmentedDiminished2;

var isFifth = function isFifth(interval) {
  return interval === _MinorMajor.PERFECT_FIFTH || interval === _AugmentedDiminished.AUGMENTED_FIFTH || interval === _AugmentedDiminished.DIMINISHED_FIFTH;
};

exports.default = isFifth;
exports = exports["default"];
export default exports;