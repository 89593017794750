import _getChromaticCPosition3 from "../getChromaticCPosition";
import _getOctave3 from "../getOctave";
import _areNotes3 from "../areNotes";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getChromaticCPosition = _getChromaticCPosition3;

var _getChromaticCPosition2 = _interopRequireDefault(_getChromaticCPosition);

var _getOctave = _getOctave3;

var _getOctave2 = _interopRequireDefault(_getOctave);

var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var areEqual = function areEqual(notes) {
  if (!(0, _areNotes2.default)(notes)) {
    return false;
  }

  try {
    return notes.map(_getChromaticCPosition2.default).every(function (v, _, arr) {
      return v === arr[0];
    }) && notes.map(_getOctave2.default).every(function (v, _, arr) {
      return v === arr[0];
    });
  } catch (e) {
    return false;
  }
};

exports.default = areEqual;
exports = exports["default"];
export default exports;