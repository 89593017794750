import _hasIntervalAmount3 from "../hasIntervalAmount";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _hasIntervalAmount = _hasIntervalAmount3;

var _hasIntervalAmount2 = _interopRequireDefault(_hasIntervalAmount);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isPentatonic = function isPentatonic(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  return (0, _hasIntervalAmount2.default)(scale, 5, {
    direction: direction
  });
}; // https://en.wikipedia.org/wiki/Pentatonic_scale


exports.default = isPentatonic;
exports = exports["default"];
export default exports;