import _hasOctave3 from "../hasOctave";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _hasOctave = _hasOctave3;

var _hasOctave2 = _interopRequireDefault(_hasOctave);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var areNotes = function areNotes(notes) {
  if (!Array.isArray(notes)) return false;

  try {
    var isValid = notes.filter(function (n) {
      return (0, _hasOctave2.default)(n);
    }).length === notes.length || notes.filter(function (n) {
      return !(0, _hasOctave2.default)(n);
    }).length === notes.length;
    return notes.every(function (n) {
      return (0, _isNote2.default)(n);
    }) && isValid;
  } catch (e) {
    return false;
  }
};

exports.default = areNotes;
exports = exports["default"];
export default exports;