import _getIntervals3 from "../getIntervals";
import _isNote3 from "../isNote";
import _hasOctave3 from "../hasOctave";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _hasOctave = _hasOctave3;

var _hasOctave2 = _interopRequireDefault(_hasOctave);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // standard: which note is 60? TIL: this is vendor specific


exports.default = function (note) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$standard = _ref.standard,
      standard = _ref$standard === undefined ? "C4" : _ref$standard;

  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  if (!(0, _hasOctave2.default)(note)) {
    throw new Error("\"" + note + "\" does not contain octave information.");
  }

  if (!(0, _isNote2.default)(standard)) {
    throw new Error("\"" + standard + "\" is not a valid note");
  }

  var interval = (0, _getIntervals2.default)([standard, note])[0];
  return 60 + interval;
};

exports = exports["default"];
export default exports;