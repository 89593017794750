import _Accidental2 from "../constants/Accidental";
import _noteToObject3 from "../noteToObject";
import _objectToNote3 from "../objectToNote";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Accidental = _Accidental2;
var _noteToObject = _noteToObject3;

var _noteToObject2 = _interopRequireDefault(_noteToObject);

var _objectToNote = _objectToNote3;

var _objectToNote2 = _interopRequireDefault(_objectToNote);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var accidentalToSymbol = function accidentalToSymbol(note) {
  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  var noteObject = (0, _noteToObject2.default)(note);
  var accidentalType = noteObject.accidentalType;
  if (accidentalType !== _Accidental.LETTER) return note;
  noteObject.accidentalType = _Accidental.SYMBOL;
  return (0, _objectToNote2.default)(noteObject);
};

exports.default = accidentalToSymbol;
exports = exports["default"];
export default exports;