import _Names3 from "./Names";
import _Short3 from "./Short";
import _MinorMajor3 from "./MinorMajor";
import _AugmentedDiminished3 from "./AugmentedDiminished";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
}; // https://en.wikipedia.org/wiki/Interval_(music)#Interval_number_and_quality


var _Names = _Names3;

var _Names2 = _interopRequireDefault(_Names);

var _Short = _Short3;

var _Short2 = _interopRequireDefault(_Short);

var _MinorMajor = _MinorMajor3;

var _MinorMajor2 = _interopRequireDefault(_MinorMajor);

var _AugmentedDiminished = _AugmentedDiminished3;

var _AugmentedDiminished2 = _interopRequireDefault(_AugmentedDiminished);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = _extends({}, _Short2.default, _Names2.default, _MinorMajor2.default, _AugmentedDiminished2.default);
exports = exports["default"];
export default exports;