import _transferAccidentalStyle3 from "../transferAccidentalStyle";
import _transferAccidental3 from "../transferAccidental";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _transferAccidentalStyle = _transferAccidentalStyle3;

var _transferAccidentalStyle2 = _interopRequireDefault(_transferAccidentalStyle);

var _transferAccidental = _transferAccidental3;

var _transferAccidental2 = _interopRequireDefault(_transferAccidental);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var transferStyle = function transferStyle(note, referenceNote) {
  if (!(0, _isNote2.default)(referenceNote)) {
    throw new Error("\"" + referenceNote + "\" is not a valid reference note.");
  }

  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  return (0, _transferAccidental2.default)((0, _transferAccidentalStyle2.default)(note, referenceNote), referenceNote);
}; // should transfer flat or sharp style preference (sharp / flat - symbol / letter)
// if reference has no accidental it should mirror it's input style


exports.default = transferStyle;
exports = exports["default"];
export default exports;