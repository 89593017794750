import _Names2 from "../constants/Interval/Names";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Names = _Names2;

var isSemitone = function isSemitone(interval) {
  return interval === _Names.SEMITONE;
};

exports.default = isSemitone;
exports = exports["default"];
export default exports;