import _isNote3 from "../isNote";
import _Accidental2 from "../constants/Accidental";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _Accidental = _Accidental2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getAccidental = function getAccidental(accidental, accidentalType) {
  if (accidental.toUpperCase() === _Accidental.FLAT) {
    if (accidentalType.toUpperCase() === _Accidental.LETTER) return _Accidental.FLAT_LETTER;
    return _Accidental.FLAT_SYMBOL;
  } else if (accidental.toUpperCase() === _Accidental.SHARP) {
    if (accidentalType.toUpperCase() === _Accidental.LETTER) return _Accidental.SHARP_LETTER;
    return _Accidental.SHARP_SYMBOL;
  }

  return "";
};

var objectToNote = function objectToNote(noteObject) {
  var _noteObject$root = noteObject.root,
      root = _noteObject$root === undefined ? "" : _noteObject$root,
      _noteObject$accidenta = noteObject.accidental,
      accidental = _noteObject$accidenta === undefined ? "" : _noteObject$accidenta,
      _noteObject$accidenta2 = noteObject.accidentalType,
      accidentalType = _noteObject$accidenta2 === undefined ? "" : _noteObject$accidenta2,
      _noteObject$octave = noteObject.octave,
      octave = _noteObject$octave === undefined ? "" : _noteObject$octave;
  var scientificNote = "" + root.toUpperCase() + getAccidental(accidental, accidentalType) + octave;

  if (!(0, _isNote2.default)(scientificNote)) {
    throw new Error("\"" + JSON.stringify(noteObject) + "\" is not a valid note object.");
  }

  return scientificNote;
};

exports.default = objectToNote;
exports = exports["default"];
export default exports;