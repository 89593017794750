import _areNotes3 from "../areNotes";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var createMelody = function createMelody(notes, pattern) {
  if (!(0, _areNotes2.default)(notes)) {
    throw new Error("Please provide an array of scientific notes");
  }

  if (!Array.isArray(pattern) || pattern.some(function (v) {
    return v > notes.length - 1;
  })) {
    throw new Error("Please provide an array with note positions (max position: " + (notes.length - 1) + ")");
  }

  return pattern.map(function (v) {
    return notes[v];
  });
};

exports.default = createMelody;
exports = exports["default"];
export default exports;