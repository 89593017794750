import _accidentalToLetter3 from "../accidentalToLetter";
import _accidentalToSymbol3 from "../accidentalToSymbol";
import _noteToObject5 from "../noteToObject";
import _hasAccidental3 from "../hasAccidental";
import _isNote3 from "../isNote";
import _Accidental2 from "../constants/Accidental";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _accidentalToLetter = _accidentalToLetter3;

var _accidentalToLetter2 = _interopRequireDefault(_accidentalToLetter);

var _accidentalToSymbol = _accidentalToSymbol3;

var _accidentalToSymbol2 = _interopRequireDefault(_accidentalToSymbol);

var _noteToObject3 = _noteToObject5;

var _noteToObject4 = _interopRequireDefault(_noteToObject3);

var _hasAccidental = _hasAccidental3;

var _hasAccidental2 = _interopRequireDefault(_hasAccidental);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _Accidental = _Accidental2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var transferAccidentalStyle = function transferAccidentalStyle(note, referenceNote) {
  if (!(0, _isNote2.default)(referenceNote)) {
    throw new Error("\"" + referenceNote + "\" is not a valid reference note.");
  }

  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  if (!(0, _hasAccidental2.default)(referenceNote)) return note;

  var _noteToObject = (0, _noteToObject4.default)(referenceNote),
      rAccidentalType = _noteToObject.accidentalType;

  var _noteToObject2 = (0, _noteToObject4.default)(note),
      iAccidentalType = _noteToObject2.accidentalType;

  if (rAccidentalType === iAccidentalType) return note;
  if (rAccidentalType === _Accidental.LETTER) return (0, _accidentalToLetter2.default)(note);
  return (0, _accidentalToSymbol2.default)(note);
};

exports.default = transferAccidentalStyle;
exports = exports["default"];
export default exports;