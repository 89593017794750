import _normalize3 from "../normalize";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getNoteOnDegree = function getNoteOnDegree(scale, degree) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  var normalizedScale = (0, _normalize2.default)(scale, {
    direction: direction
  });

  if (degree < 1 || degree > normalizedScale.length) {
    throw new Error("Degree must be between 1 - " + normalizedScale.length + ".");
  }

  return normalizedScale[degree - 1];
};

exports.default = getNoteOnDegree;
exports = exports["default"];
export default exports;