import _noteToObject3 from "../noteToObject";
import _areNotes3 from "../areNotes";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _noteToObject = _noteToObject3;

var _noteToObject2 = _interopRequireDefault(_noteToObject);

var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var uniq = function uniq(arr) {
  return arr.filter(function (e, i, a) {
    return a.indexOf(e) === i;
  });
};

var haveSameOctave = function haveSameOctave() {
  var notes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (!(0, _areNotes2.default)(notes) || notes.length < 2) return false;
  return uniq(notes.map(_noteToObject2.default).map(function (o) {
    return o.octave;
  })).length === 1;
};

exports.default = haveSameOctave;
exports = exports["default"];
export default exports;