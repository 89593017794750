import _IONIAN3 from "./IONIAN";
import _DORIAN3 from "./DORIAN";
import _PHRYGIAN3 from "./PHRYGIAN";
import _LYDIAN3 from "./LYDIAN";
import _MIXOLYDIAN3 from "./MIXOLYDIAN";
import _AEOLIAN3 from "./AEOLIAN";
import _LOCRIAN3 from "./LOCRIAN";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _IONIAN = _IONIAN3;

var _IONIAN2 = _interopRequireDefault(_IONIAN);

var _DORIAN = _DORIAN3;

var _DORIAN2 = _interopRequireDefault(_DORIAN);

var _PHRYGIAN = _PHRYGIAN3;

var _PHRYGIAN2 = _interopRequireDefault(_PHRYGIAN);

var _LYDIAN = _LYDIAN3;

var _LYDIAN2 = _interopRequireDefault(_LYDIAN);

var _MIXOLYDIAN = _MIXOLYDIAN3;

var _MIXOLYDIAN2 = _interopRequireDefault(_MIXOLYDIAN);

var _AEOLIAN = _AEOLIAN3;

var _AEOLIAN2 = _interopRequireDefault(_AEOLIAN);

var _LOCRIAN = _LOCRIAN3;

var _LOCRIAN2 = _interopRequireDefault(_LOCRIAN);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // B -> B (white keys)
// G -> G (white keys)
// E -> E (white keys)
// C -> C (white keys)


exports.default = {
  IONIAN: _IONIAN2.default,
  DORIAN: _DORIAN2.default,
  PHRYGIAN: _PHRYGIAN2.default,
  LYDIAN: _LYDIAN2.default,
  MIXOLYDIAN: _MIXOLYDIAN2.default,
  AEOLIAN: _AEOLIAN2.default,
  LOCRIAN: _LOCRIAN2.default
}; // A -> A (white keys)
// F -> F (white keys)
// D -> D (white keys)
// https://en.wikipedia.org/wiki/Mode_(music)
// formed by natural notes, also called "white-notes"
// as they can be played using the white keys of a piano keyboard

exports = exports["default"];
export default exports;