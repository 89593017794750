import _Chord3 from "./constants/Chord";
import _Interval3 from "./constants/Interval";
import _Mode3 from "./constants/Mode";
import _Scale3 from "./constants/Scale";
import _NOTES3 from "./constants/NOTES";
import _accidentalToLetter3 from "./accidentalToLetter";
import _accidentalToSymbol3 from "./accidentalToSymbol";
import _areEqual3 from "./areEqual";
import _createChord3 from "./createChord";
import _createMelody3 from "./createMelody";
import _createScale3 from "./createScale";
import _flatToSharp3 from "./flatToSharp";
import _getAccidental3 from "./getAccidental";
import _getChromaticCPosition3 from "./getChromaticCPosition";
import _getDominant3 from "./getDominant";
import _getIntervals3 from "./getIntervals";
import _getLeadingTone3 from "./getLeadingTone";
import _getMediant3 from "./getMediant";
import _getNote3 from "./getNote";
import _getOctave3 from "./getOctave";
import _getRoot3 from "./getRoot";
import _getNoteOnDegree3 from "./getNoteOnDegree";
import _getSubdominant3 from "./getSubdominant";
import _getSubmediant3 from "./getSubmediant";
import _getSupertonic3 from "./getSupertonic";
import _getTonic3 from "./getTonic";
import _hasAccidental3 from "./hasAccidental";
import _hasAccidentalLetter3 from "./hasAccidentalLetter";
import _hasAccidentalSymbol3 from "./hasAccidentalSymbol";
import _hasOctave3 from "./hasOctave";
import _haveSameOctave3 from "./haveSameOctave";
import _isAnhemitonic3 from "./isAnhemitonic";
import _isAscending3 from "./isAscending";
import _isCohemitonic3 from "./isCohemitonic";
import _isDescending3 from "./isDescending";
import _isDiatonic3 from "./isDiatonic";
import _isFifth3 from "./isFifth";
import _isFlat3 from "./isFlat";
import _isHemitonic3 from "./isHemitonic";
import _isHeptatonic3 from "./isHeptatonic";
import _isHexatonic3 from "./isHexatonic";
import _areIntervals3 from "./areIntervals";
import _isMode3 from "./isMode";
import _isNatural3 from "./isNatural";
import _isOctatonic3 from "./isOctatonic";
import _isOctave3 from "./isOctave";
import _isPentatonic3 from "./isPentatonic";
import _isScale3 from "./isScale";
import _hasIntervalAmount3 from "./hasIntervalAmount";
import _isNote3 from "./isNote";
import _areNotes3 from "./areNotes";
import _isSemitone3 from "./isSemitone";
import _isSharp3 from "./isSharp";
import _isTone3 from "./isTone";
import _isTriad3 from "./isTriad";
import _normalize3 from "./normalize";
import _noteToFrequency3 from "./noteToFrequency";
import _noteToMidi3 from "./noteToMidi";
import _noteToObject3 from "./noteToObject";
import _objectToNote3 from "./objectToNote";
import _sharpToFlat3 from "./sharpToFlat";
import _transferAccidental3 from "./transferAccidental";
import _transferStyle3 from "./transferStyle";
import _transpose3 from "./transpose";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Chord = _Chord3;

var _Chord2 = _interopRequireDefault(_Chord);

var _Interval = _Interval3;

var _Interval2 = _interopRequireDefault(_Interval);

var _Mode = _Mode3;

var _Mode2 = _interopRequireDefault(_Mode);

var _Scale = _Scale3;

var _Scale2 = _interopRequireDefault(_Scale);

var _NOTES = _NOTES3;

var _NOTES2 = _interopRequireDefault(_NOTES);

var _accidentalToLetter = _accidentalToLetter3;

var _accidentalToLetter2 = _interopRequireDefault(_accidentalToLetter);

var _accidentalToSymbol = _accidentalToSymbol3;

var _accidentalToSymbol2 = _interopRequireDefault(_accidentalToSymbol);

var _areEqual = _areEqual3;

var _areEqual2 = _interopRequireDefault(_areEqual);

var _createChord = _createChord3;

var _createChord2 = _interopRequireDefault(_createChord);

var _createMelody = _createMelody3;

var _createMelody2 = _interopRequireDefault(_createMelody);

var _createScale = _createScale3;

var _createScale2 = _interopRequireDefault(_createScale);

var _flatToSharp = _flatToSharp3;

var _flatToSharp2 = _interopRequireDefault(_flatToSharp);

var _getAccidental = _getAccidental3;

var _getAccidental2 = _interopRequireDefault(_getAccidental);

var _getChromaticCPosition = _getChromaticCPosition3;

var _getChromaticCPosition2 = _interopRequireDefault(_getChromaticCPosition);

var _getDominant = _getDominant3;

var _getDominant2 = _interopRequireDefault(_getDominant);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _getLeadingTone = _getLeadingTone3;

var _getLeadingTone2 = _interopRequireDefault(_getLeadingTone);

var _getMediant = _getMediant3;

var _getMediant2 = _interopRequireDefault(_getMediant);

var _getNote = _getNote3;

var _getNote2 = _interopRequireDefault(_getNote);

var _getOctave = _getOctave3;

var _getOctave2 = _interopRequireDefault(_getOctave);

var _getRoot = _getRoot3;

var _getRoot2 = _interopRequireDefault(_getRoot);

var _getNoteOnDegree = _getNoteOnDegree3;

var _getNoteOnDegree2 = _interopRequireDefault(_getNoteOnDegree);

var _getSubdominant = _getSubdominant3;

var _getSubdominant2 = _interopRequireDefault(_getSubdominant);

var _getSubmediant = _getSubmediant3;

var _getSubmediant2 = _interopRequireDefault(_getSubmediant);

var _getSupertonic = _getSupertonic3;

var _getSupertonic2 = _interopRequireDefault(_getSupertonic);

var _getTonic = _getTonic3;

var _getTonic2 = _interopRequireDefault(_getTonic);

var _hasAccidental = _hasAccidental3;

var _hasAccidental2 = _interopRequireDefault(_hasAccidental);

var _hasAccidentalLetter = _hasAccidentalLetter3;

var _hasAccidentalLetter2 = _interopRequireDefault(_hasAccidentalLetter);

var _hasAccidentalSymbol = _hasAccidentalSymbol3;

var _hasAccidentalSymbol2 = _interopRequireDefault(_hasAccidentalSymbol);

var _hasOctave = _hasOctave3;

var _hasOctave2 = _interopRequireDefault(_hasOctave);

var _haveSameOctave = _haveSameOctave3;

var _haveSameOctave2 = _interopRequireDefault(_haveSameOctave);

var _isAnhemitonic = _isAnhemitonic3;

var _isAnhemitonic2 = _interopRequireDefault(_isAnhemitonic);

var _isAscending = _isAscending3;

var _isAscending2 = _interopRequireDefault(_isAscending);

var _isCohemitonic = _isCohemitonic3;

var _isCohemitonic2 = _interopRequireDefault(_isCohemitonic);

var _isDescending = _isDescending3;

var _isDescending2 = _interopRequireDefault(_isDescending);

var _isDiatonic = _isDiatonic3;

var _isDiatonic2 = _interopRequireDefault(_isDiatonic);

var _isFifth = _isFifth3;

var _isFifth2 = _interopRequireDefault(_isFifth);

var _isFlat = _isFlat3;

var _isFlat2 = _interopRequireDefault(_isFlat);

var _isHemitonic = _isHemitonic3;

var _isHemitonic2 = _interopRequireDefault(_isHemitonic);

var _isHeptatonic = _isHeptatonic3;

var _isHeptatonic2 = _interopRequireDefault(_isHeptatonic);

var _isHexatonic = _isHexatonic3;

var _isHexatonic2 = _interopRequireDefault(_isHexatonic);

var _areIntervals = _areIntervals3;

var _areIntervals2 = _interopRequireDefault(_areIntervals);

var _isMode = _isMode3;

var _isMode2 = _interopRequireDefault(_isMode);

var _isNatural = _isNatural3;

var _isNatural2 = _interopRequireDefault(_isNatural);

var _isOctatonic = _isOctatonic3;

var _isOctatonic2 = _interopRequireDefault(_isOctatonic);

var _isOctave = _isOctave3;

var _isOctave2 = _interopRequireDefault(_isOctave);

var _isPentatonic = _isPentatonic3;

var _isPentatonic2 = _interopRequireDefault(_isPentatonic);

var _isScale = _isScale3;

var _isScale2 = _interopRequireDefault(_isScale);

var _hasIntervalAmount = _hasIntervalAmount3;

var _hasIntervalAmount2 = _interopRequireDefault(_hasIntervalAmount);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _areNotes = _areNotes3;

var _areNotes2 = _interopRequireDefault(_areNotes);

var _isSemitone = _isSemitone3;

var _isSemitone2 = _interopRequireDefault(_isSemitone);

var _isSharp = _isSharp3;

var _isSharp2 = _interopRequireDefault(_isSharp);

var _isTone = _isTone3;

var _isTone2 = _interopRequireDefault(_isTone);

var _isTriad = _isTriad3;

var _isTriad2 = _interopRequireDefault(_isTriad);

var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

var _noteToFrequency = _noteToFrequency3;

var _noteToFrequency2 = _interopRequireDefault(_noteToFrequency);

var _noteToMidi = _noteToMidi3;

var _noteToMidi2 = _interopRequireDefault(_noteToMidi);

var _noteToObject = _noteToObject3;

var _noteToObject2 = _interopRequireDefault(_noteToObject);

var _objectToNote = _objectToNote3;

var _objectToNote2 = _interopRequireDefault(_objectToNote);

var _sharpToFlat = _sharpToFlat3;

var _sharpToFlat2 = _interopRequireDefault(_sharpToFlat);

var _transferAccidental = _transferAccidental3;

var _transferAccidental2 = _interopRequireDefault(_transferAccidental);

var _transferStyle = _transferStyle3;

var _transferStyle2 = _interopRequireDefault(_transferStyle);

var _transpose = _transpose3;

var _transpose2 = _interopRequireDefault(_transpose);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = {
  Chord: _Chord2.default,
  Interval: _Interval2.default,
  Mode: _Mode2.default,
  Scale: _Scale2.default,
  NOTES: _NOTES2.default,
  accidentalToLetter: _accidentalToLetter2.default,
  accidentalToSymbol: _accidentalToSymbol2.default,
  areEqual: _areEqual2.default,
  createChord: _createChord2.default,
  createMelody: _createMelody2.default,
  createScale: _createScale2.default,
  flatToSharp: _flatToSharp2.default,
  getAccidental: _getAccidental2.default,
  getChromaticCPosition: _getChromaticCPosition2.default,
  getDominant: _getDominant2.default,
  getIntervals: _getIntervals2.default,
  getLeadingTone: _getLeadingTone2.default,
  getMediant: _getMediant2.default,
  getNote: _getNote2.default,
  getOctave: _getOctave2.default,
  getRoot: _getRoot2.default,
  getNoteOnDegree: _getNoteOnDegree2.default,
  getSubdominant: _getSubdominant2.default,
  getSubmediant: _getSubmediant2.default,
  getSupertonic: _getSupertonic2.default,
  getTonic: _getTonic2.default,
  hasAccidental: _hasAccidental2.default,
  hasAccidentalLetter: _hasAccidentalLetter2.default,
  hasAccidentalSymbol: _hasAccidentalSymbol2.default,
  hasOctave: _hasOctave2.default,
  haveSameOctave: _haveSameOctave2.default,
  isAnhemitonic: _isAnhemitonic2.default,
  isAscending: _isAscending2.default,
  isCohemitonic: _isCohemitonic2.default,
  isDescending: _isDescending2.default,
  isDiatonic: _isDiatonic2.default,
  isFifth: _isFifth2.default,
  isFlat: _isFlat2.default,
  isHemitonic: _isHemitonic2.default,
  isHeptatonic: _isHeptatonic2.default,
  isHexatonic: _isHexatonic2.default,
  areIntervals: _areIntervals2.default,
  isMode: _isMode2.default,
  isNatural: _isNatural2.default,
  isOctatonic: _isOctatonic2.default,
  isOctave: _isOctave2.default,
  isPentatonic: _isPentatonic2.default,
  isScale: _isScale2.default,
  hasIntervalAmount: _hasIntervalAmount2.default,
  isNote: _isNote2.default,
  areNotes: _areNotes2.default,
  isSemitone: _isSemitone2.default,
  isSharp: _isSharp2.default,
  isTone: _isTone2.default,
  isTriad: _isTriad2.default,
  normalize: _normalize2.default,
  noteToFrequency: _noteToFrequency2.default,
  noteToMidi: _noteToMidi2.default,
  noteToObject: _noteToObject2.default,
  objectToNote: _objectToNote2.default,
  sharpToFlat: _sharpToFlat2.default,
  transferAccidental: _transferAccidental2.default,
  transferStyle: _transferStyle2.default,
  transpose: _transpose2.default
};
exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;