import _getNote3 from "../getNote";
import _isNote3 from "../isNote";
import _NOTES3 from "../constants/NOTES";
import _accidentalToLetter3 from "../accidentalToLetter";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getNote = _getNote3;

var _getNote2 = _interopRequireDefault(_getNote);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _NOTES = _NOTES3;

var _NOTES2 = _interopRequireDefault(_NOTES);

var _accidentalToLetter = _accidentalToLetter3;

var _accidentalToLetter2 = _interopRequireDefault(_accidentalToLetter);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getChromaticCPosition = function getChromaticCPosition(note) {
  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  var rNote = (0, _getNote2.default)((0, _accidentalToLetter2.default)(note));
  return _NOTES2.default.findIndex(function (n) {
    return n.some(function (nn) {
      return nn === rNote;
    });
  });
};

exports.default = getChromaticCPosition;
exports = exports["default"];
export default exports;