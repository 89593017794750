var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
}); // https://en.wikipedia.org/wiki/Interval_(music)#Interval_number_and_quality

var R = exports.R = 0;
var S = exports.S = 1;
var T = exports.T = 2;
var TT = exports.TT = 6;
var O = exports.O = 12;
exports.default = {
  R: R,
  S: S,
  T: T,
  TT: TT,
  O: O
};
export default exports;