import _isFlat3 from "../isFlat";
import _isSharp3 from "../isSharp";
import _isNote3 from "../isNote";
import _Accidental2 from "../constants/Accidental";
import _hasAccidental3 from "../hasAccidental";
import _hasAccidentalLetter3 from "../hasAccidentalLetter";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _isFlat = _isFlat3;

var _isFlat2 = _interopRequireDefault(_isFlat);

var _isSharp = _isSharp3;

var _isSharp2 = _interopRequireDefault(_isSharp);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _Accidental = _Accidental2;
var _hasAccidental = _hasAccidental3;

var _hasAccidental2 = _interopRequireDefault(_hasAccidental);

var _hasAccidentalLetter = _hasAccidentalLetter3;

var _hasAccidentalLetter2 = _interopRequireDefault(_hasAccidentalLetter);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getAccidental = function getAccidental(note) {
  if (!(0, _isNote2.default)(note)) {
    throw new Error("\"" + note + "\" is not a valid note.");
  }

  if (!(0, _hasAccidental2.default)(note)) return undefined;

  if ((0, _isFlat2.default)(note)) {
    if ((0, _hasAccidentalLetter2.default)(note)) return _Accidental.FLAT_LETTER;
    return _Accidental.FLAT_SYMBOL;
  } else if ((0, _isSharp2.default)(note)) {
    if ((0, _hasAccidentalLetter2.default)(note)) return _Accidental.SHARP_LETTER;
    return _Accidental.SHARP_SYMBOL;
  }

  return note;
};

exports.default = getAccidental;
exports = exports["default"];
export default exports;