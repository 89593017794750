import _hasAccidental3 from "../hasAccidental";
import _isNote3 from "../isNote";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _hasAccidental = _hasAccidental3;

var _hasAccidental2 = _interopRequireDefault(_hasAccidental);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isNatural = function isNatural(note) {
  if (!(0, _isNote2.default)(note)) {
    return false;
  }

  return !(0, _hasAccidental2.default)(note);
};

exports.default = isNatural;
exports = exports["default"];
export default exports;