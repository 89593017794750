import _isHeptatonic3 from "../isHeptatonic";
import _getIntervals3 from "../getIntervals";
import _normalize3 from "../normalize";
import _isSemitone3 from "../isSemitone";
import _isTone3 from "../isTone";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}(); // https://en.wikipedia.org/wiki/Diatonic_scale


var _isHeptatonic = _isHeptatonic3;

var _isHeptatonic2 = _interopRequireDefault(_isHeptatonic);

var _getIntervals = _getIntervals3;

var _getIntervals2 = _interopRequireDefault(_getIntervals);

var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

var _isSemitone = _isSemitone3;

var _isSemitone2 = _interopRequireDefault(_isSemitone);

var _isTone = _isTone3;

var _isTone2 = _interopRequireDefault(_isTone);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isDiatonic = function isDiatonic(scale) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (!(0, _isHeptatonic2.default)(scale, {
    direction: direction
  })) return false;
  var normalizedScale = (0, _normalize2.default)(scale, {
    direction: direction
  });
  var intervals = (0, _getIntervals2.default)(normalizedScale);
  if (intervals.filter(_isSemitone2.default).length !== 2) return false;
  if (intervals.filter(_isTone2.default).length !== 5) return false;

  var _intervals$map$filter = intervals.map(function (interval, position) {
    return (0, _isSemitone2.default)(interval) ? position : undefined;
  }).filter(function (position) {
    return position !== undefined;
  }),
      _intervals$map$filter2 = _slicedToArray(_intervals$map$filter, 2),
      pos1 = _intervals$map$filter2[0],
      pos2 = _intervals$map$filter2[1];

  if (pos2 - pos1 < 2) return false;
  return true;
};

exports.default = isDiatonic;
exports = exports["default"];
export default exports;