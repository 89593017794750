import _Short2 from "./Short";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.A7 = exports.AUGMENTED_SEVENTH = exports.d8 = exports.DIMINISHED_OCTAVE = exports.DIMINISHED_EIGHTH = exports.A6 = exports.AUGMENTED_SIXTH = exports.d7 = exports.DIMINISHED_SEVENTH = exports.A5 = exports.AUGMENTED_FIFTH = exports.d6 = exports.DIMINISHED_SIXTH = exports.A4 = exports.AUGMENTED_FOURTH = exports.d5 = exports.DIMINISHED_FIFTH = exports.A3 = exports.AUGMENTED_THIRD = exports.d4 = exports.DIMINISHED_FOURTH = exports.A2 = exports.AUGMENTED_SECOND = exports.d3 = exports.DIMINISHED_THIRD = exports.A1 = exports.AUGMENTED_UNISON = exports.d2 = exports.DIMINISHED_SECOND = undefined;
var _Short = _Short2;
var DIMINISHED_SECOND = exports.DIMINISHED_SECOND = _Short.R; // https://en.wikipedia.org/wiki/Interval_(music)#Interval_number_and_quality

var d2 = exports.d2 = DIMINISHED_SECOND;
var AUGMENTED_UNISON = exports.AUGMENTED_UNISON = _Short.S;
var A1 = exports.A1 = AUGMENTED_UNISON;
var DIMINISHED_THIRD = exports.DIMINISHED_THIRD = _Short.T;
var d3 = exports.d3 = DIMINISHED_THIRD;
var AUGMENTED_SECOND = exports.AUGMENTED_SECOND = 3 * _Short.S;
var A2 = exports.A2 = AUGMENTED_SECOND;
var DIMINISHED_FOURTH = exports.DIMINISHED_FOURTH = 4 * _Short.S;
var d4 = exports.d4 = DIMINISHED_FOURTH;
var AUGMENTED_THIRD = exports.AUGMENTED_THIRD = 5 * _Short.S;
var A3 = exports.A3 = AUGMENTED_THIRD;
var DIMINISHED_FIFTH = exports.DIMINISHED_FIFTH = _Short.TT;
var d5 = exports.d5 = DIMINISHED_FIFTH;
var AUGMENTED_FOURTH = exports.AUGMENTED_FOURTH = _Short.TT;
var A4 = exports.A4 = _Short.TT;
var DIMINISHED_SIXTH = exports.DIMINISHED_SIXTH = 7 * _Short.S;
var d6 = exports.d6 = DIMINISHED_SIXTH;
var AUGMENTED_FIFTH = exports.AUGMENTED_FIFTH = 8 * _Short.S;
var A5 = exports.A5 = AUGMENTED_FIFTH;
var DIMINISHED_SEVENTH = exports.DIMINISHED_SEVENTH = 9 * _Short.S;
var d7 = exports.d7 = DIMINISHED_SEVENTH;
var AUGMENTED_SIXTH = exports.AUGMENTED_SIXTH = 10 * _Short.S;
var A6 = exports.A6 = AUGMENTED_SIXTH;
var DIMINISHED_EIGHTH = exports.DIMINISHED_EIGHTH = 11 * _Short.S;
var DIMINISHED_OCTAVE = exports.DIMINISHED_OCTAVE = DIMINISHED_EIGHTH;
var d8 = exports.d8 = DIMINISHED_EIGHTH;
var AUGMENTED_SEVENTH = exports.AUGMENTED_SEVENTH = _Short.O;
var A7 = exports.A7 = AUGMENTED_SEVENTH;
exports.default = {
  DIMINISHED_SECOND: DIMINISHED_SECOND,
  d2: d2,
  AUGMENTED_UNISON: AUGMENTED_UNISON,
  A1: A1,
  DIMINISHED_THIRD: DIMINISHED_THIRD,
  d3: d3,
  AUGMENTED_SECOND: AUGMENTED_SECOND,
  A2: A2,
  DIMINISHED_FOURTH: DIMINISHED_FOURTH,
  d4: d4,
  AUGMENTED_THIRD: AUGMENTED_THIRD,
  A3: A3,
  DIMINISHED_FIFTH: DIMINISHED_FIFTH,
  d5: d5,
  AUGMENTED_FOURTH: AUGMENTED_FOURTH,
  A4: A4,
  DIMINISHED_SIXTH: DIMINISHED_SIXTH,
  d6: d6,
  AUGMENTED_FIFTH: AUGMENTED_FIFTH,
  A5: A5,
  DIMINISHED_SEVENTH: DIMINISHED_SEVENTH,
  d7: d7,
  AUGMENTED_SIXTH: AUGMENTED_SIXTH,
  A6: A6,
  DIMINISHED_OCTAVE: DIMINISHED_OCTAVE,
  DIMINISHED_EIGHTH: DIMINISHED_EIGHTH,
  d8: d8,
  AUGMENTED_SEVENTH: AUGMENTED_SEVENTH,
  A7: A7
};
export default exports;