import _AUGMENTED3 from "./AUGMENTED";
import _DIMINISHED3 from "./DIMINISHED";
import _MAJOR_SEVENTH3 from "./MAJOR_SEVENTH";
import _MAJOR3 from "./MAJOR";
import _MINOR_MAJOR_SEVENTH3 from "./MINOR_MAJOR_SEVENTH";
import _MINOR_SEVENTH3 from "./MINOR_SEVENTH";
import _MINOR3 from "./MINOR";
import _SEVENTH3 from "./SEVENTH";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _AUGMENTED = _AUGMENTED3;

var _AUGMENTED2 = _interopRequireDefault(_AUGMENTED);

var _DIMINISHED = _DIMINISHED3;

var _DIMINISHED2 = _interopRequireDefault(_DIMINISHED);

var _MAJOR_SEVENTH = _MAJOR_SEVENTH3;

var _MAJOR_SEVENTH2 = _interopRequireDefault(_MAJOR_SEVENTH);

var _MAJOR = _MAJOR3;

var _MAJOR2 = _interopRequireDefault(_MAJOR);

var _MINOR_MAJOR_SEVENTH = _MINOR_MAJOR_SEVENTH3;

var _MINOR_MAJOR_SEVENTH2 = _interopRequireDefault(_MINOR_MAJOR_SEVENTH);

var _MINOR_SEVENTH = _MINOR_SEVENTH3;

var _MINOR_SEVENTH2 = _interopRequireDefault(_MINOR_SEVENTH);

var _MINOR = _MINOR3;

var _MINOR2 = _interopRequireDefault(_MINOR);

var _SEVENTH = _SEVENTH3;

var _SEVENTH2 = _interopRequireDefault(_SEVENTH);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = {
  AUGMENTED: _AUGMENTED2.default,
  DIMINISHED: _DIMINISHED2.default,
  MAJOR_SEVENTH: _MAJOR_SEVENTH2.default,
  MAJOR: _MAJOR2.default,
  MINOR_MAJOR_SEVENTH: _MINOR_MAJOR_SEVENTH2.default,
  MINOR_SEVENTH: _MINOR_SEVENTH2.default,
  MINOR: _MINOR2.default,
  SEVENTH: _SEVENTH2.default
};
exports = exports["default"];
export default exports;