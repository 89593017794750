import _noteToObject3 from "../noteToObject";
import _isNote3 from "../isNote";
import _Accidental2 from "../constants/Accidental";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _noteToObject = _noteToObject3;

var _noteToObject2 = _interopRequireDefault(_noteToObject);

var _isNote = _isNote3;

var _isNote2 = _interopRequireDefault(_isNote);

var _Accidental = _Accidental2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isFlat = function isFlat(note) {
  if (!(0, _isNote2.default)(note)) {
    return false;
  }

  return (0, _noteToObject2.default)(note).accidental === _Accidental.FLAT;
};

exports.default = isFlat;
exports = exports["default"];
export default exports;