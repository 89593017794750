import _Names2 from "../Interval/Names";
import _MinorMajor2 from "../Interval/MinorMajor";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Names = _Names2;
var _MinorMajor = _MinorMajor2; // https://en.wikipedia.org/wiki/Minor_chord

exports.default = [_Names.ROOT, _MinorMajor.MINOR_THIRD, _MinorMajor.PERFECT_FIFTH];
exports = exports["default"];
export default exports;