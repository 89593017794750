import _SCIENTIFIC_NOTE3 from "../constants/Regex/SCIENTIFIC_NOTE";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _SCIENTIFIC_NOTE = _SCIENTIFIC_NOTE3;

var _SCIENTIFIC_NOTE2 = _interopRequireDefault(_SCIENTIFIC_NOTE);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var isNote = function isNote(scientificNote) {
  return _SCIENTIFIC_NOTE2.default.test(scientificNote);
};

exports.default = isNote;
exports = exports["default"];
export default exports;