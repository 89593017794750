import _isScale3 from "../isScale";
import _normalize3 from "../normalize";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _isScale = _isScale3;

var _isScale2 = _interopRequireDefault(_isScale);

var _normalize = _normalize3;

var _normalize2 = _interopRequireDefault(_normalize);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // https://en.wikipedia.org/wiki/Heptatonic_scale


var hasIntervalAmount = function hasIntervalAmount(scale, intervalAmount) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$direction = _ref.direction,
      direction = _ref$direction === undefined ? 1 : _ref$direction;

  if (![-1, 1].includes(direction)) {
    throw new Error("Direction should be 1 (up) or -1 (down)");
  }

  try {
    var normalizedScale = (0, _normalize2.default)(scale, {
      direction: direction
    });
    return normalizedScale.length === intervalAmount + 1 && (0, _isScale2.default)(normalizedScale);
  } catch (e) {
    return false;
  }
};

exports.default = hasIntervalAmount;
exports = exports["default"];
export default exports;