var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var FLAT_SYMBOL = exports.FLAT_SYMBOL = "\u266D";
var FLAT_LETTER = exports.FLAT_LETTER = "b";
var SHARP_SYMBOL = exports.SHARP_SYMBOL = "\u266F";
var SHARP_LETTER = exports.SHARP_LETTER = "#";
var LETTER = exports.LETTER = "LETTER";
var SYMBOL = exports.SYMBOL = "SYMBOL";
var SHARP = exports.SHARP = "SHARP";
var FLAT = exports.FLAT = "FLAT";
exports.default = {
  FLAT_SYMBOL: FLAT_SYMBOL,
  FLAT_LETTER: FLAT_LETTER,
  SHARP_SYMBOL: SHARP_SYMBOL,
  SHARP_LETTER: SHARP_LETTER,
  FLAT: FLAT,
  SHARP: SHARP,
  LETTER: LETTER,
  SYMBOL: SYMBOL
};
export default exports;